<script setup>
import FormInput from "~/components/atoms/FormInput.vue";
import MiniCollectionPreview from "~/components/molecules/MiniCollectionPreview.vue";

const props = defineProps({
  sku: {
    type: String,
    required: true
  },
  selects: {
    type: Object,
    required: true
  },
  collection: {
    type: Object,
    required: false
  }
})

const emit = defineEmits(['change'])

const activeSelect = ref(null)

watch(() => activeSelect.value, () => {
  if (activeSelect.value === null) return

  emit('change', activeSelect.value)

  activeSelect.value = null
})

</script>

<template>
  <template v-if="!props.selects?.selects.length">
    <div v-if="props.collection?.name">
      <div class="full-width row g-24">
        <div>
        </div>
        <div>
          <MiniCollectionPreview :name="props.collection?.name" :slug="props.collection.slug"
                                 :image="'https://hillceramic.se/'+props.collection.image"/>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="grid-2 the-same-grid g-8">
      <div class="selects" v-for="(select,index) in props.selects.selects">
        <FormInput class="clickable" type="select"
                   @click="activeSelect = index"
                   :options="[{key:select.products[0].name,value:select.products[0].name}]" disabled
                   :label="select?.site_name"
                   v-model="select.products[0].name"/>
      </div>

      <MiniCollectionPreview v-if="props.collection?.name" :name="props.collection.name" :slug="props.collection.slug"
                             :image="'https://hillceramic.se/'+props.collection.image"/>
    </div>
  </template>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.selects:deep(input) {
  font-weight: 600;
  font-size: 13px;
}

.selects:deep(span) {
  font-size: 11px !important;
}

.selects:deep(.input-group) {
  &:hover {
    background-color: hsla(0, 0%, 97.6%, .9);
  }
}
</style>
